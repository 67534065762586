import React from "react"
import ContactForm from "../../components/ContactForm"
import ReferralFAQ from "../../components/ReferralFAQ"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
const REFERRAL_PERCENT = 0.2 / 100
const MULTIPLIER = 10000
const MONTHS = 3

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const Referral = () => {
  const [slider, setSlider] = React.useState(50)
  return (
    <Layout>
      <SEO title="Refer Lister" />

      <div className="container contact grid">
        <div className="contact__content">
          <h1 className="referral__title">Love Lister?</h1>
          <p>Get paid by referring customers to Upright Labs.</p>
          <p>
            Refer a customer to Lister, and we'll give you 0.20% of their
            revenue made via Lister for the first 3 months + a $200 Amazon gift
            card.
          </p>
          <p>
            You can refer as many customers as you'd like. We’ll send payouts in
            terms of statement credit.
          </p>
        </div>

        <div className="form-contact contact__form">
          <h3 className="referral-header">Calculate your bonus</h3>

          <p className="referral-label">
            Total monthly revenue of customers you refer
          </p>

          <input
            type="range"
            min="1"
            max="100"
            value={slider}
            onChange={e => {
              setSlider(e.target.value)
            }}
            class="slider"
          />
          <p className="referral-bonus">
            ${numberWithCommas(slider * MULTIPLIER)}/ month
          </p>

          <p className="referral-label">
            Your referral bonus for next three months
          </p>
          <p className="referral-bonus">
            ${numberWithCommas(slider * MULTIPLIER * REFERRAL_PERCENT)}/ month
          </p>

          <a
            href={`mailto:sales@uprightlabs.com?cc=person-to-refer@example.com&subject=Referral for Upright Lister&body=Hi Upright Team! I wanted to introduce you to [your referrals name here] from [referral's business here]. They're cc'd here on this email.`}
          >
            <button type="submit" className="button">
              Get started
            </button>
          </a>
        </div>
      </div>

      <article className="company__story">
        <div className="container grid">
          <div className="company__story-holder">
            <ReferralFAQ></ReferralFAQ>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default Referral
