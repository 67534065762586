import React from "react"
import { Link } from "gatsby"

export default () => {
  return (
    <>
      <h2 className="h2--bold">FAQ's</h2>
      <div>
        <p>
          <b>How many submissions can I make?</b> <br></br> As many as you want!
          There's no cutoff for submissions, so you could submit 5 clients
          tomorrow or 2 clients next year.
        </p>
        <p>
          <b>How do I actually do a referral?</b>
          <br></br> Send an email to{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="mailto:sales@uprightlabs.com"
          >
            sales@uprightlabs.com
          </a>{" "}
          and the person or company you want us to be connected with cc'd on the
          email.
        </p>
        <p>
          <b>What should I put in a referral email?</b>
          <br></br> Whatever you want! But here is an example: <br></br>
          <br></br>
          "Hi Upright Team! I wanted to introduce you to (your referrals name
          here) from (referral's business here). They're cc'd here on this
          email. We thought Upright Labs would be helpful for their organization
          to help solve (an issue or inefficiency region is seeing)."
        </p>
        <p>
          <b>Do I need to be an Upright Labs client to make a referral?</b>
          <br></br>
          You do! But if you aren't already we can get on you board and then you
          can start earning money towards your monthly bill.
        </p>
        <p>
          <b>How does the gift card work?</b>
          <br></br>
          When you refer another company to Upright Labs, you both get a $200
          Amazon gift card. Limit of 5 referrals/company.
        </p>
        <p>
          <b>Can I see the fine print?</b>
          <br></br>
          Sure thing! Check out our{" "}
          <Link style={{ textDecoration: "underline" }} to="/refer/policy">
            referral policy
          </Link>
        </p>
      </div>
    </>
  )
}
